<template>
    <div style="margin: 10px">

        



        <!-- Tabla 1 -->
    <div class="row">
        <div class="col-lg-6">
          <table class="table table-bordered">
            <thead>
              <th>Predicciones recientes relacionadas</th>
            </thead>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </table>
        </div>
  
        <!-- Tabla 2 -->
        <div class="col-lg-6">
          <table class="table table-bordered">
            <thead>
              <th>Historial de predicciones</th>
            </thead>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </table>
        </div>
      </div>

      <form ref="form">
        <!-- Otros campos omitidos por brevedad -->
  
        <div class="row">
          <!-- Tipo de Impugnación -->
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="tipoImpugnacion" class="form-label" aria-label="Tipo de Impugnación *">
              Tipo de Impugnación *
            </label>
            <v-select
              :options="tiposImpugnacion"
              id="tipoImpugnacion"
              v-model="form.tipoImpugnacion"
              label="texto"
              placeholder="Selecciona"
              aria-label="Tipo de Impugnación"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.tipoImpugnacion"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <slot name="no-options">No se encontraron coincidencias.</slot>
            </v-select>
          </div>
  
          <!-- Ponente -->
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="ponente" class="form-label" aria-label="Ponente">
              Ponente *
            </label>
            <v-select
              :options="ponentes"
              id="ponente"
              v-model="form.ponente"
              label="texto"
              placeholder="Selecciona"
              aria-label="Ponente"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.modalidadesViolencia"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
              <slot name="no-options">No se encontraron coincidencias.</slot>
            </v-select>
          </div>
  
          <!-- Fecha -->
          <div class="mb-3 col-lg-4 col-xs-12">
            <label for="fecha" class="form-label" aria-label="Fecha">
              Fecha (Preferente de turno)
            </label>
            <input
              type="date"
              id="fecha"
              class="form-control"
              v-model.trim="form.fecha"
              aria-label="Fecha"
            />
          </div>
        </div>
  
        <!-- Modalidades de Violencia Política -->
        <div class="mb-3">
          <label for="modalidadesViolencia" class="form-label" aria-label="Modalidades de Violencia Política *">
            Modalidades de Violencia Política *
          </label>
          <v-select
            :options="modalidadesViolencia"
            id="modalidadesViolencia"
            v-model="form.modalidadesViolencia"
            label="texto"
            placeholder="Selecciona"
            aria-label="Modalidades de Violencia Política"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!form.modalidadesViolencia"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>
  
        <!-- Resto de los campos omitidos por brevedad -->
      </form>
    </div>
  </template>

  <script>
  
  import "vue-select/dist/vue-select.css";
  import {
    //required,
  } from "vuelidate/lib/validators";
  import { mapState, mapActions, mapMutations } from "vuex";
  
  export default {
    props: ["clickedNext", "currentStep"],
    name: "DatosExpediente",
    mounted() {
      // this.getStates();
      /* console.log("mounted", this.$v.$invalid); */
      this.setMunicipios([]);
      if (!this.$v.$invalid) {
        this.$emit("can-continue", { value: true });
      } else {
        // this.verTerminos();
        this.$emit("can-continue", { value: false });
      }
    },
    activated: function () {
      /* console.log("activate"); */
      if (!this.$v.form.$invalid) {
        // console.log("activate can");
        this.$emit("can-continue", { value: true });
      } else {
        // console.log("activate cant");
        this.$emit("can-continue", { value: false });
      }
    },
    deactivated: function () {
      // console.log("deactivated");
    },
    data() {
      return {
        form: {
          tipoImpugnacion: "",
          modalidadesViolencia: "",
          ponente:"",
          fecha:"",
        },
        tiposImpugnacion: ["AG", "JDC", "JE", "JRC", "PES","PSC", "RAP", "REC", "REP", "RIN"],
        modalidadesViolencia: [
            "Frases en RRSS que contribuyen a vpg",
            "Violencia simbólica",
            "Acoso laboral",
            "Discriminación y vpg",
            "No remuneración del cargo",
            "Obstrucción del cargo",
            "Obstrucción del cargo y vpg",
            "Obstrucción del ejercicio constituyendo vpg",
            "Publicaciones en RRSS resultando vpg",
            "Violencia Institucional y vpg",
            "Violencia política",
            "Violencia psicológica",
            "Vpg",
            "Vpg nulidad de elección",
            "Vpg, simbólica"
            ],
        ponentes: [
            "Reyes Rodríguez Mondragón",
            "Indalfer Infante Gonzales",
            "Janine M. Otálora Malassis",
            "Felipe de la Mata Pizaña",
            "Mónica Aralí Soto Fregoso",
            "Felipe Alfredo Fuentes Barrera",
            "José Luis Vargas Valdez",
            "Tania Celina Vásquez Muñoz",
            "Roberto Eduardo Sígala Aguilar",
            "José Oliveros Ruiz",
            "Eva Barrientos Zepeda",
            "Enrique Figueroa Ávila",
            "Adín Antonio de León Gálvez",
            "Rubén Jesús Lara Patrón",
            "Claudia Díaz Tablada",
            "Luis Espíndola Morales",
            "Gabriela Villafuerte Coello",
            "Ernesto Camacho Ochoa",
            "Gabriela Eugenia Del Valle Pérez",
            "Sergio Arturo Guerrero Olvera",
            "José Antonio Troncoso Ávila",
            "Gustavo César Pale Beristain",
            "José Antonio Hernández Huesca"
          ]
      };
    },
    
    validations() {
      return {
        form: {
          fecha: {
            
          },
          tipoImpugnacion: {
            
          },
          ponente: {
            
          },
          modalidadViolencia: {
           
          },
        },
      };
    },

    
    computed: {
      ...mapState("generals", [
        "organosJurisdiccionales",
        "cargosEleccionPopular",
        "tipoDocData",
        "generosData",
        "ciudades",
        "estados",
      ]),
    },
    methods: {
      ...mapMutations("contenido", ["setFormatoDatos"]),
      ...mapMutations("generals", ["setMunicipios",]),
      ...mapActions({
        getStates: "generals/getStates",
        getMunicipios: "generals/getMunicipios",
        emitirDatosCompletos() {

          this.$emit('datos-completos', this.form);
        },

        recopilarDatos() {
          return {
            tipoImpugnacion: this.form.tipoImpugnacion,
            modalidadesViolencia: this.form.modalidadViolencia,
            ponente: this.form.ponente,
            fecha: this.fecha,
          };
        },


        submitForm() {
          this.$v.form.$touch(); // Asegura que se realice la validación
          if (!this.$v.form.$invalid) {
            // Si el formulario es válido, imprime los datos en la consola
            //console.log("Datos del formulario:", this.form);
          }
        },
      }),
    },
    watch: {
      $v: {
        handler: function (val) {
          // console.log("es invalido cambio.... ", val.$invalid);

          //console.log("Datos del formulario:", this.form);
          if (!val.$invalid) {
            this.$emit("can-continue", { value: true });
            this.setFormatoDatos(this.form);
          } else {
            this.$emit("can-continue", { value: false });
          }
        },
        deep: true,
      },
      clickedNext(val) {
        if (val === true) {
          this.$v.form.$touch();
          this.submitForm();
          // this.setFormatoDatos(this.form)
        }
      },
      
    },
  };
  </script>
  <style>
  .form-control {
    height: calc(1.5em + 0.75rem + 2px);
  }
  .vs__dropdown-toggle {
    border: 1px solid #eee;
    height: calc(1.5em + 0.75rem + 2px);
  }
  .v-select {
    height: calc(1.5em + 0.75rem + 2px);
    box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
  }
  .error {
    border: 1px solid #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  th {
    background-color: #1d4073;
    color: white; /* Texto blanco para contrastar con el fondo */
  }

  .table-bordered {
    border: 1px solid black;
  }
  </style>