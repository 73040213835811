<template>
    <div class="homepage-3">
      <ScrollupSection />
      <div class="main overflow-hidden">
        <HeaderSection />
        <BreadcrumbSection :titulo="titulo" :descripcion="descripcion" />
        <section class="section service-area">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <horizontal-stepper ref="pasosVPG"
                  :steps="demoSteps"
                  @completed-step="completeStep"
                  @active-step="isStepActive"
                  @stepper-finished="alert"
                  class="m-5"
                  locale="es"
                  :myData="myData"
                >
                </horizontal-stepper>
              </div>
            </div>
          </div>
        </section>
        <FooterSection/>
        <ModalSearchSection />
        <ModalRegistro/>
        <ModalMenuSection />
        <ModalTerms/>
        <ModalCapcha :titulo="tituloFormato"  :nombre-archivo="tituloArchivo" :url="url" :tipo-formato="1"/>
        <Modal :show="showModal" :modalHeadMessage="modalHeadMessage" :modalMessage="modalMessage" @close="showModal = false"></Modal>
      </div>
    </div>
  
  </template>
  
  <script>
  import { analytics, auth} from "@/firebase.js";
  import { mapActions, mapMutations } from "vuex";
  import ScrollupSection from "../Scrollup/Scrollup";
  import HeaderSection from "../Header/HeaderOne";
  import BreadcrumbSection from "../Breadcrumb/Services";
  import FooterSection from "../Footer/FooterOne";
  
  import ModalSearchSection from "../Modal/ModalSearch/ModalSearch";
  import ModalRegistro from "../Modal/ModalSearch/ModalRegistro";
  import ModalMenuSection from "../Modal/ModalMenu/ModalMenu";
  import ModalTerms from '../Modal/ModalTerms/ModalTerms';
  import ModalCapcha from "@/components/Modal/ModalCaptcha/ModalCapcha";
  
  import HorizontalStepper from "vue-stepper";
  import "vue-select/dist/vue-select.css";
  //import Pruebas from "../../components/forms/VG/Pruebas";
  //import GeneralFormRepresentate from "../../components/forms/GeneralesRepresentante";
  import GeneralFormInteresado from "../../components/formsChatET/GeneralInteresado";
  //import Generals from "../../components/forms/Generals";
  //import Complement from "../../components/forms/VG/Complement";
  //import MotivosAgravio from "../../components/forms/VG/MotivosAgravio";
  import DatosExpediente from "../../components/formsChatET/typePrediction/VPG";
  //import Riegos from "../../components/forms/VG/Riesgos";
  import axios from 'axios';
  //import { GoogleAuth } from 'google-auth-library';
  import Modal from '../Modal/ModalFormats/ModalBuild.vue';
  import { fr } from '../../firebase';
  //import VPG from "../formsChatET/typePrediction/VPG.vue";
  

  export default {
    name: "ViolenciaPoilitcaGenero",
    metaInfo: {
      title: "Electoral Tech & Legan | Violencia política en razón de género",
      description:
        "Descripción",
    },
    components: {
      ScrollupSection,
      HeaderSection,
      BreadcrumbSection,
      FooterSection,
      ModalSearchSection,
      ModalMenuSection,
      ModalRegistro,
      ModalTerms,
      HorizontalStepper,
      ModalCapcha,
      Modal
    },

    
    mounted() {
      analytics.logEvent("formatoComenzado", { name: "Violencia política en razón de género" });
      window.$("#modalTerminos").modal("show");
      this.setTipoFormato("VPG");
      // window.$("#ModalCaptcha").modal("show");
    },
    data() {
      return {
        showModal: false,
        modalMessage: '',
        titulo: "Violencia política en razón de género",
        tituloFormato: "Violencia politica en razón de género",
        tituloArchivo: "formato-politica-en-razon-genero.pdf",
        descripcion:"",
        urlProd:
          "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/generarVPRG",
        urlLocal:
          "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/generarVPRG",
        demoSteps: [
          {
            icon: "person",
            name: "first",
            title: "Datos del interesado",
            // subtitle: "Subtitle sample",
            component: GeneralFormInteresado,
            completed: false,
          },
          {
            icon: "list",
            name: "second",
            title: "Datos del expediente",
            // subtitle: "Subtitle sample",
            component: DatosExpediente,
            completed: false,

          },
          // {
          //   icon: "speaker_notes",
          //   name: "third",
          //   title: "Predicción",
          //   // subtitle: "Subtitle sample",
          //   component: GeneralFormInteresado,
          //   completed: false,
          // },
        ],
        myData: {
        step1: null,
        step2: null
        },
      };
    },
    computed: {
      url() {
        return process.env.NODE_ENV == "development"
          ? this.urlLocal
          : this.urlProd;
      },
    },
    methods: {
      ...mapMutations('generals', ['setTipoFormato']),
      ...mapActions('contenido', ['guardarPrediccion', 'oneHotEncodingVPG'] ),
      completeStep(payload) {
        this.demoSteps.forEach((step) => {
          if (step.name === payload.name) {
            step.completed = true;
          }
        });
      },
      errorMessage(error) {
        this.$swal({
          icon: "error",
          title: error,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
        });
      },
      // Executed when @active-step event is triggered
      isStepActive(payload) {
        this.demoSteps.forEach((step) => {
          if (step.name === payload.name) {
            if (step.completed === true) {
              step.completed = false;
            }
          }
        });
      },

      async handleStepperFinished() {
        try {
          await this.showFormData();
        } catch (error) {
          console.error('Error al manejar finalización del stepper:', error);
          // Manejar errores
        }
      },

      handleDatosExpedienteCompletos(datos) {
      console.log('Datos del expediente completos:', datos);
      },

      async showFormData() {
        try {
          await this.$nextTick();

          // Acceder a los datos de GeneralFormInteresado
          const datosGeneralInteresado = this.$refs.demoSteps[0].componentInstance.$refs.form;
          console.log("Datos del General Interesado:", datosGeneralInteresado);

          // Acceder a los datos de VPG (DatosExpediente)
          const datosVPG = this.$refs.datosExpediente.$refs.form;
          console.log("Datos de VPG:", datosVPG);

          // Emitir evento para manejar los datos de VPG
          this.$refs.datosExpediente.emitirDatosCompletos();
        } catch (error) {
          console.error('Error al mostrar los datos:', error);
          // Manejar el error adecuadamente
        }
      },

      async checkSubscriptionStatus() {
        try {
          const userId = auth.currentUser.uid;

          const snapshot = await fr.collection('suscriptoresPremiumPlus')
                                    .where('idUsuario', '==', userId)
                                    .where('estado', '==', 'activo')
                                    .get();

          if (!snapshot.empty) {
            const doc = snapshot.docs[0];
            console.log('Usuario encontrado:', doc.data());
            this.procesarPrediccion();
          } else {
            console.log('El usuario no tiene una suscripción activa.');
            this.modalHeadMessage = 'Se requiere suscripción a Premium+';
            this.modalMessage = 'Ve a "Ver Perfil" para gestionar tu suscripción';
            this.showModal = true;
          }
        } catch (error) {
          console.error('Error en checkSubscriptionStatus:', error);
          throw error; // Lanza el error para que sea capturado por el método alert()
        }
      },

      wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      async procesarPrediccion() {
        try {
          this.$swal({
            icon: "info",
            title: "Realizando predicción",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timerProgressBar: true,
            showCancelButton: true,
            didOpen: () => this.$swal.showLoading(),
          });

          const datosExp = {
            'TipoInpugnacion': this.$refs.pasosVPG.$children[1].form.tipoImpugnacion,
            'Ponente': this.$refs.pasosVPG.$children[1].form.ponente,
            'ModalidadesViolenciaPolitica': this.$refs.pasosVPG.$children[1].form.modalidadesViolencia
          };
          
          // console.log("Datos", datosExp)

          const instanciaCodificada = await this.oneHotEncodingVPG(datosExp);
          // await this.wait(10000);

          const fechaActual = new Date();

          const datosPPRV = {
            appMaterno: this.$refs.pasosVPG.$children[0].form.appMat,
            appPaterno: this.$refs.pasosVPG.$children[0].form.appPat,
            codigoPostal: this.$refs.pasosVPG.$children[0].form.cp,
            correoElectronico: this.$refs.pasosVPG.$children[0].form.correo,
            fechaRegistro: fechaActual,
            idUsuario: auth.currentUser.uid,
            modalidades: this.$refs.pasosVPG.$children[1].form.modalidadesViolencia,
            nombre: this.$refs.pasosVPG.$children[0].form.nombres,
            ponente: this.$refs.pasosVPG.$children[1].form.ponente,
            telefono: this.$refs.pasosVPG.$children[0].form.telefono,
            tema: "VPG",
            tipoImpugnacion: this.$refs.pasosVPG.$children[1].form.tipoImpugnacion,
          };

          const pprvDocRef = await fr.collection('predicciones').doc('VPG').collection('PPRV').add(datosPPRV);
          const pprvDocId = pprvDocRef.id;

          const response = await axios.post("https://us-central1-electoraltech-d9cec.cloudfunctions.net/predicVPG",{
              instances: [instanciaCodificada],
            }
          )

          
          const datosResultados = {
            pprvDocId: pprvDocId,
            diasTranscurridos: Math.round(response.data[2]),
            fechaRegistro: fechaActual,
            idUsuario: auth.currentUser.uid,
            modalidades: this.$refs.pasosVPG.$children[1].form.modalidadesViolencia,
            ponente: this.$refs.pasosVPG.$children[1].form.ponente,
            sentido: response.data[0],
            tema: "VPG",
            tipoImpugnacion: this.$refs.pasosVPG.$children[1].form.tipoImpugnacion,
            votacion: response.data[1],
          };

          await fr.collection('predicciones').doc('VPG').collection('resultados').add(datosResultados);


          // const instanceDatas = [
          //     [0, 1,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  1,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0, 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  1,  0, 0,  0]
          // ];

          // const response = await axios.post("https://us-central1-electoraltech-d9cec.cloudfunctions.net/predicVPG",{
          //   instances: instanceDatas,
          // }
          // )

          // Manejo de la respuesta
          // console.log("Resultado de la predicción:", response.data);
          // console.log("Resultado de la predicción:", instanceDatas);


          this.$swal({
            icon: "success",
            title: "Predicción de expediente con temática de Violencia política en razón de género",
            text: `Para ver tu predicción, debes ir a tu perfil`,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              console.log('Usuario confirmó');
              this.$router.push({ name: "perfil" });
            } else if (result.isDenied) {
              console.log('Usuario no confirmó');
            }
          });

        } catch (error) {
          console.warn("Error al procesar la predicción:", error);
          this.errorMessage("Revise su conexión a internet y pruebe más tarde");
        }
      },

    
      
      async alert() {
        // window.$("#ModalCaptcha").modal("show");}
        if ( auth.currentUser != null ) {
            try {
            await this.checkSubscriptionStatus();

            

          } catch (error) {
            console.error('Error en alert:', error);
            this.errorMessage('Revise su conexión a internet y pruebe más tarde');
          }
            
        // try{
        //   this.$swal({
        //     icon: "info",
        //     title: "Guardando comentario",
        //     allowOutsideClick: false,
        //     allowEscapeKey: false,
        //     allowEnterKey: false,
        //     timerProgressBar: true,
        //     showCancelButton: true,
        //     didOpen: () => this.$swal.showLoading(),
        //   });
        //   await this.$nextTick();


        //   const datosExpediente = this.$refs.datosExpediente?.form?.tipoImpugnacion;
        //   console.log("Datos del expediente:", datosExpediente);
        //   console.log("Datos del general:", GeneralFormInteresado)
        //   await this.guardarPrediccion({nombreFormato: this.tituloFormato, tipoFormato: "VPG", datosExpediente: datosExpediente});


        //   this.$swal({
        //     icon: "success",
        //     title: "Predicción de expediente con temática de Violencia politica en razón de género",
        //     text: `Para ver tu preddición, debes ir a tu perfil, revisar la información de pago, y subir tu comprobante de pago`,
        //     allowOutsideClick: false,
        //     allowEscapeKey: false,
        //     allowEnterKey: false,
        //   }).then((result) => {
        //     // Read more about isConfirmed, isDenied below 
        //     if (result.isConfirmed) {
        //       console.log('confirmo')
        //       this.$router.push({ name: "perfil" });
        //     } else if (result.isDenied) {
        //       console.log('no confirmo')
        //     }
        //   });

          
          // Guardar datos en Firebase
          // await db.ref('datos').push({
          // feature1: this.feature1,
          // feature2: this.feature2,
          // Otros campos según tus necesidades
          // });
        // }catch (e) {
        //   console.warn(e);
        //   this.errorMessage('Revise su conexión a internet y pruebe más tarde');
        // }
      } else {
        const result = await this.$swal({
          icon: "info",
          title: "Registrate o Inicia sesión!",
          text: "Crea tu cuenta o inicia sesión para ver tu predicción en tu perfil",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Crear cuenta ahora',
        });
        if (result.isConfirmed) {
          window.$("#registro").modal('show');
        } else if (result.isDenied) {
          this.$router.push({ name: "inicio" });
          console.log('no confirmo')
        }
      }
      },
    },
  };
  </script>
  
  <style>
  .stepper-box .content {
    overflow: visible !important;
  }
  </style>